import axios from 'axios';
import router from '@/router/index';

// 默认超时设置
axios.defaults.timeout = 25000;
// 相对路径设置
// axios.defaults.baseURL = '/server_api';
// axios.defaults.baseURL = 'https://jinejia.xiangda.group/yjkpapi';
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/server_api';
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = '/server_api';
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://www.gdnos.com.cn/api';
}

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    const data = config;
    // 获取token
    const token = sessionStorage.getItem('jwt_token');
    // 设置参数格式
    if (!config.headers['Content-Type']) {
      data.headers['Content-Type'] = 'application/json;charset=UTF-8';
      // {
      //   'Content-Type': 'application/json;charset=UTF-8',
      //   // application/x-www-form-urlencoded;charset=UTF-8
      // };
    }
    // 添加token到headers
    if (token) {
      data.headers.jwttoken = token;
    }
    // 鉴权参数设置
    // if (data.method === 'get') {
    //   // get请求下 参数在params中，其他请求在data中
    //   config.params = config.params || {};
    //   const json = JSON.parse(JSON.stringify(config.params));
    //   // 一些参数处理
    // } else {
    //   config.data = config.data || {};
    //   // 一些参数处理
    // }
    return data;
  },
  (err) => Promise.reject(err),
);

// http response 拦截器
axios.interceptors.response.use(
  (res) => {
    // 一些统一code的返回处理
    if (res.data.code === 400) { // jwt_token 失效重新登录
      // 登录验证
      // 做了个示例跳转项目中登录，并记录下相对路径
      console.log(router);
      // sessionStorage.setItem('jwt_token', null);
      // sessionStorage.setItem('yjkp_user', null);
      // router.push({ path: '/' });
      // window.location.href = '/';
    }
    return res.data;
  },
  (error) => Promise.reject(error),
);

export default axios;
