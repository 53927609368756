import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    meta: {
      // title: '首页',
      auth: false,
    },
  }, {
    path: '/xueshuyanjiu',
    name: 'XueShuYanJiu',
    component: () => import(/* webpackChunkName: "about" */ '../views/XueShuYanJiu.vue'),
    meta: {
      // title: '学术研究',
      auth: false,
    },
  }, {
    path: '/zixunlist',
    name: 'ZiXunList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZiXunList.vue'),
    meta: {
      // title: '资讯列表',
      auth: false,
    },
  }, {
    path: '/zixundetail',
    name: 'ZiXunDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZiXunDetail.vue'),
    meta: {
      // title: '资讯详情',
      auth: false,
    },
  }, {
    path: '/xuehuijianjie',
    name: 'XueHuiJianJie',
    component: () => import(/* webpackChunkName: "about" */ '../views/XueHuiJianJie.vue'),
    meta: {
      // title: '学会简介',
      auth: false,
    },
  }, {
    path: '/huizhangjianjie',
    name: 'HuiZhangJianJie',
    component: () => import(/* webpackChunkName: "about" */ '../views/HuiZhangJianJie.vue'),
    meta: {
      // title: '历任会长简介',
      auth: false,
    },
  }, {
    path: '/xianrenlingdao',
    name: 'XianRenLingDao',
    component: () => import(/* webpackChunkName: "about" */ '../views/XianRenLingDao.vue'),
    meta: {
      // title: '现任领导简介',
      auth: false,
    },
  }, {
    path: '/changwulishimingdan',
    name: 'ChangWuLiShiMingDan',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangWuLiShiMingDan.vue'),
    meta: {
      // title: '常务理事名单',
      auth: false,
    },
  }, {
    path: '/lishimingdan',
    name: 'LiShiMingDan',
    component: () => import(/* webpackChunkName: "about" */ '../views/LiShiMingDan.vue'),
    meta: {
      // title: '理事名单',
      auth: false,
    },
  }, {
    path: '/zhuhuidangjian',
    name: 'ZhuHuiDangJian',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZhuHuiDangJian.vue'),
    meta: {
      // title: '驻会党建',
      auth: false,
    },
  }, {
    path: '/dangjianzixundetail',
    name: 'DangJianZiXunDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/DangJianZiXunDetail.vue'),
    meta: {
      // title: '党建资讯详情',
      auth: false,
    },
  }, {
    path: '/zhuhuirenyuan',
    name: 'ZhuHuiRenYuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZhuHuiRenYuan.vue'),
    meta: {
      // title: '驻会工作人员',
      auth: false,
    },
  }, {
    path: '/yuedongzhonghua',
    name: 'YueDongZhongHua',
    component: () => import(/* webpackChunkName: "about" */ '../views/YueDongZhongHua.vue'),
    meta: {
      // title: '乐动中华',
      auth: false,
    },
  }, {
    path: '/zhuanjiaweiyuanhui',
    name: 'ZhuanJiaWeiYuanHui',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZhuanJiaWeiYuanHui.vue'),
    meta: {
      // title: '专家委员会',
      auth: false,
    },
  }, {
    path: '/otherweiyuanhui',
    name: 'OtherWeiYuanHui',
    component: () => import(/* webpackChunkName: "about" */ '../views/OtherWeiYuanHui.vue'),
    meta: {
      // title: '专家委员会',
      auth: false,
    },
  }, {
    path: '/huiyuanzhuanqu',
    name: 'HuiYuanZhuanQu',
    component: () => import(/* webpackChunkName: "about" */ '../views/HuiYuanZhuanQu.vue'),
    meta: {
      // title: '会员专区',
      auth: false,
    },
  }, {
    path: '/minyuekaoji',
    name: 'MinYueKaoJi',
    component: () => import(/* webpackChunkName: "about" */ '../views/MinYueKaoJi.vue'),
    meta: {
      // title: '民乐考级',
      auth: false,
    },
  }, {
    path: '/yanxiubaoming',
    name: 'YanXiuBaoMing',
    component: () => import(/* webpackChunkName: "about" */ '../views/YanXiuBaoMing.vue'),
    meta: {
      // title: '研修报名',
      auth: false,
    },
  }, {
    path: '/minyuedasai',
    name: 'MinYueDaSai',
    component: () => import(/* webpackChunkName: "about" */ '../views/MinYueDaSai.vue'),
    meta: {
      // title: '民乐大赛',
      auth: false,
    },
  }, {
    path: '/yuepujieyue',
    name: 'YuePuJieYue',
    component: () => import(/* webpackChunkName: "about" */ '../views/YuePuJieYue.vue'),
    meta: {
      // title: '乐谱借阅',
      auth: false,
    },
  }, {
    path: '/minyuebao',
    name: 'MinYueBao',
    component: () => import(/* webpackChunkName: "about" */ '../views/MinYueBao.vue'),
    meta: {
      // title: '民乐报',
      auth: false,
    },
  }, {
    path: '/dasaibaoming',
    name: 'Dasaibaoming',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dasaibaoming.vue'),
    meta: {
      // title: '大赛报名页',
      auth: false,
    },
  }, {
    path: '/dasaichengji',
    name: 'Dasaichengji',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dasaichengji.vue'),
    meta: {
      // title: '大赛成绩查询',
      auth: false,
    },
  }, {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../admin/Login.vue'),
    meta: {
      // title: '后台管理登录页',
      auth: false,
    },
  }, {
    path: '/admin/center',
    name: 'AdminCenter',
    component: () => import(/* webpackChunkName: "about" */ '../admin/Admin.vue'),
    redirect: { name: 'adminIndex' }, // 输入路由重定向到adminIndex页面
    children: [{
      path: '/',
      name: 'adminIndex',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Index.vue'),
      meta: {
        // title: '后台管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'zixuntype',
      name: 'ZiXunType',
      component: () => import(/* webpackChunkName: "about" */ '../admin/ZiXunType.vue'),
      meta: {
        // title: '资讯类别管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'zixun',
      name: 'ZiXun',
      component: () => import(/* webpackChunkName: "about" */ '../admin/ZiXun.vue'),
      meta: {
        // title: '资讯管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'lunbotu',
      name: 'LunBoTu',
      component: () => import(/* webpackChunkName: "about" */ '../admin/LunBoTu.vue'),
      meta: {
        // title: '轮播图管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'systemset',
      name: 'SystemSet',
      component: () => import(/* webpackChunkName: "about" */ '../admin/SystemSet.vue'),
      meta: {
        // title: '系统设置',
        auth: true,
        role: 1,
      },
    }, {
      path: 'huizhang',
      name: 'Huizhang',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Huizhang.vue'),
      meta: {
        // title: '历任会长管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'lingdao',
      name: 'Lingdao',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Lingdao.vue'),
      meta: {
        // title: '现任领导管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'worker',
      name: 'Worker',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Worker.vue'),
      meta: {
        // title: '驻会工作人员管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'book',
      name: 'Book',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Book.vue'),
      meta: {
        // title: '书籍 民乐报管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'fenzhi',
      name: 'Fenzhi',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Fenzhi.vue'),
      meta: {
        // title: '分支机构管理',
        auth: true,
        role: 1,
      },
    }, {
      path: 'chusai',
      name: 'Chusai',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Chusai.vue'),
      meta: {
        // title: '报名资料',
        auth: true,
        role: 1,
      },
    }, {
      path: 'fusai',
      name: 'Fusai',
      component: () => import(/* webpackChunkName: "about" */ '../admin/Fusai.vue'),
      meta: {
        // title: '报名资料',
        auth: true,
        role: 1,
      },
    }],
  }, {
    path: '/saichang',
    name: 'Saichang',
    component: () => import(/* webpackChunkName: "about" */ '../admin/Saichang.vue'),
    meta: {
      // title: '报名资料',
      // auth: true,
      // role: 1,
    },
  }, {
    path: '/oneshipin',
    name: 'Oneshipin',
    component: () => import(/* webpackChunkName: "about" */ '../admin/OneShipin.vue'),
    meta: {
      // title: '报名资料',
      // auth: true,
      // role: 1,
    },
  }, {
    path: '/fusaisaichang',
    name: 'FusaiSaichang',
    component: () => import(/* webpackChunkName: "about" */ '../admin/FusaiSaichang.vue'),
    meta: {
      // title: '报名资料',
      // auth: true,
      // role: 1,
    },
  }, { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history', // history hash
  base: process.env.BASE_URL,
  // base: '/yjkpweb/',
  routes,
});
/** 捕获到错误重新加载路由 */
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   const targetPath = router.history.pending.fullPath;
//   if (isChunkLoadFailed) {
//     router.replace(targetPath);
//   }
// });

export default router;
