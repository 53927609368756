import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';
import ElementUI from 'element-ui';
import axios from '@/router/axios';
import moment from 'moment';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import Quill from 'quill';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';
import VueParticles from 'vue-particles';
import Video from 'video.js';
import 'video.js/dist/video-js.css';
import hls from 'videojs-contrib-hls';
import router from './router';
import App from './App.vue';
import 'font-awesome/css/font-awesome.css';
import './css/my.css';

Vue.prototype.$video = Video; // 引入Video播放器
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(hls);
Vue.use(VueParticles); // 粒子特效
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
// Vue.use(VueQuillEditor);
// 设置字体样式
const Font = Quill.import('attributors/style/font'); // 引入这个后会把样式写在style上
const fonts = [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
Font.whitelist = fonts; // 将字体加入到白名单
Quill.register(Font, true);
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'image'],
        ['formula', 'clean'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ font: fonts }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ direction: 'rtl' }],
      ],
      handlers: {
        image (value) {
          if (value) {
            // 给个点击触发Element-ui，input框选择图片文件
            document.querySelector('#quill-upload input').click();
          } else {
            this.quill.format('image', false);
          }
        },
      },
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: false,
    },
    imageDrop: true,
    imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    clipboard: {
      // 粘贴版，处理粘贴时候带图片
      matchers: [[Node.ELEMENT_NODE, (node, Delta) => {
        const ops = [];
        Delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === 'string') { // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
            ops.push({
              insert: op.insert,
            });
          }
        });
        Delta.ops = ops;
        return Delta;
      }]],
    },
  },
  placeholder: '输入内容........',
});

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
// 页面跳转拦截器
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '广东省民族管弦乐学会';
  }
  console.log('路由拦截', to.path);
  console.log(to.meta);
  if (to.meta.auth) {
    const token = sessionStorage.getItem('jwt_token');
    const role = sessionStorage.getItem('role');
    if (token) {
      if (to.meta.role) {
        if (to.meta.role === parseInt(role, 10)) {
          next();
        } else {
          console.log('无权限');
        }
      } else {
        next();
      }
    } else {
      // 不存在 token，需要重新认证
      next({ path: '/login' });
    }
  } else {
    next();
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
